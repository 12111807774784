import React from "react";
import { FieldError, Merge } from "react-hook-form";

type Props = {
    label?: string;
    description?: string;
    error?: Merge<FieldError, (FieldError | undefined)[]> | FieldError;
    children: any;
    hidden?: boolean;
    textClass: string;
};

export const BaseFormGroup = ({
    label,
    description,
    children,
    error,
    hidden,
    textClass,
}: Props) => {
    return (
        <div
            className={"form-group mt-1 " + (error ? "has-error" : "")}
            hidden={hidden ?? false}
        >
            {label && (
                <label
                    className={`block text-sm font-bold leading-6 ${textClass}`}
                >
                    {label}
                </label>
            )}
            {description && (
                <p className={`text-sm leading-6 ${textClass}`}>
                    {description}
                </p>
            )}
            <div className={`text-sm leading-6 ${textClass}`}>{children}</div>
            {error && (
                <label className="label">
                    <span className="label-text-alt text-error">
                        {error.message}
                    </span>
                </label>
            )}
        </div>
    );
};

BaseFormGroup.defaultProps = {
    textClass: "text-white",
};
