import React from "react";
import { Transaction, VolunteerRegistrant } from "../../interfaces/interfaces";
import { BaseFormGroup } from "../widgets/Form";
import Swal from "sweetalert2";
import { getBadgeType, getVolunteerApiInstance } from "../../utils/helper";
import moment from "moment";
import { useIntl } from "react-intl";

type Props = {
    registrant: VolunteerRegistrant;
};

function VolunteerRegistrationDetail({ registrant }: Props) {
    const intl = useIntl();
    const handleReregister = () => {
        Swal.fire({
            icon: "warning",
            title: "Re-register?",
            text: "Apakah Anda yakin untuk melakukan registrasi ulang? Data registrasi Anda sebelumnya akan di reset kembali.",
            showCancelButton: true,
            confirmButtonText: "Ya",
            cancelButtonText: "Tidak",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
            preConfirm: () => {
                const instance = getVolunteerApiInstance();
                return instance.post(
                    `/me/registration/cancel?bill_no=${registrant.registration_status?.transaction.bill_no}`
                );
            },
        })
            .then((confirm) => {
                if (confirm.isConfirmed) {
                    document.location = "/";
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const renderSelectPaymentMethodButton = (paymentChannelId?: number) => {
        if (
            registrant.registration_status?.transaction.status === "EXPIRED" ||
            registrant.registration_status?.transaction.status === "CANCELED"
        ) {
            return (
                <div className="text-white">
                    <b>Anda belum memilih metode pembayaran</b>
                </div>
            );
        } else if (
            registrant.registration_status?.transaction.status === "WAITING"
        ) {
            if (paymentChannelId) {
                return (
                    <div className="form-control-static mb-5">
                        <b>
                            Silahkan klik tombol di bawah jika Anda ingin
                            memilih metode pembayaran lain.
                        </b>
                        <a
                            href={
                                registrant.registration_status?.transaction
                                    .payment_url
                            }
                            className="btn btn-submit mt-1 w-100"
                        >
                            Ganti metode pembayaran
                        </a>
                    </div>
                );
            } else {
                return (
                    <div className="form-control-static mb-2">
                        <b>
                            Silahkan pilih metode pembayaran untuk melanjutkan
                            proses registrasi.
                        </b>
                        <div>
                            <a
                                href={
                                    registrant.registration_status?.transaction
                                        .payment_url
                                }
                                className="btn btn-submit mt-1"
                            >
                                Pilih metode pembayaran
                            </a>
                        </div>
                    </div>
                );
            }
        }
    };
    const renderRegistrationStatus = (transaction: Transaction) => {
        return (
            <div>
                {(transaction.status === "EXPIRED" ||
                    transaction.status === "CANCELED") && (
                    <div>
                        <div className="alert alert-danger">
                            {transaction.status === "CANCELED" &&
                                "Pendaftaran Anda telah dibatalkan. Segera hubungi hotline kami jika Anda tidak merasa melakukan pembatalan."}
                            {transaction.status === "EXPIRED" &&
                                "Pendaftaran Anda telah melewati batas waktu. Jika Anda telah melakukan pembayaran tetapi belum di verifikasi, silahkan hubungi hotline dengan menyertakan bukti pembayarandan bill number Anda."}
                        </div>
                        <button
                            className="btn btn-submit w-100 mb-4"
                            onClick={handleReregister}
                        >
                            Re-register
                        </button>
                    </div>
                )}
                <div className="card">
                    <div className="card-body">
                        {/* <BaseFormGroup label="Ministry">
                            {registrant.base_ministry}
                        </BaseFormGroup>
                        <BaseFormGroup label="Shirt Size">
                            {registrant.shirt_size}
                        </BaseFormGroup> */}
                        <BaseFormGroup
                            label={intl.formatMessage({
                                id: "registrationDateLabel",
                                defaultMessage: "Tanggal Registrasi",
                            })}
                        >
                            <div className="form-control-static">
                                {moment(transaction.bill_at).format(
                                    "D MMM YYYY HH:mm"
                                )}
                            </div>
                        </BaseFormGroup>
                        <BaseFormGroup label="Total Bayar">
                            {(transaction.payment_channel_id > 0 ||
                                transaction.status === "PAID") && (
                                <div className="form-control-static">
                                    <b style={{ fontSize: "1.6em" }}>
                                        {new Intl.NumberFormat("id").format(
                                            transaction.bill_total
                                        )}
                                    </b>
                                </div>
                            )}
                            {transaction.payment_channel_id === 0 &&
                                renderSelectPaymentMethodButton()}
                        </BaseFormGroup>
                        <BaseFormGroup label="Bill No.">
                            <div className="form-control-static">
                                {transaction.bill_no}
                            </div>
                        </BaseFormGroup>
                        <BaseFormGroup label="Status">
                            <div className="form-control-static">
                                <span
                                    className={
                                        "badge " +
                                        getBadgeType(transaction.status)
                                    }
                                >
                                    {transaction.status}
                                </span>
                            </div>
                        </BaseFormGroup>
                        {transaction.status === "WAITING" && (
                            <div>
                                <BaseFormGroup label="Batas waktu pembayaran">
                                    <div className="form-control-static">
                                        <b>
                                            {moment(
                                                transaction.expired_at
                                            ).format("D MMM YYYY HH:mm")}
                                        </b>
                                    </div>
                                </BaseFormGroup>
                            </div>
                        )}
                    </div>
                </div>
                {transaction.payment_channel_id > 0 &&
                    renderSelectPaymentMethodButton(
                        transaction.payment_channel_id
                    )}
            </div>
        );
    };

    return (
        <div>
            {registrant.registration_status?.registered &&
                renderRegistrationStatus(
                    registrant.registration_status.transaction
                )}
        </div>
    );
}

export default VolunteerRegistrationDetail;
