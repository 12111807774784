import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { ApiAuthResponse, ApiResponse } from "../interfaces/interfaces";
import { jwtDecode } from "jwt-decode";


export function isAuthenticated(): boolean {
  if (sessionStorage.getItem("admin_token")) {
    return true;
  } else {
    return false;
  }
}

export function isOperatorAuthenticated(): boolean {
  if (sessionStorage.getItem("operator_token") != null) {
    return true;
  } else {
    return false;
  }
}

export function isUserAuthenticated(): boolean {
  if (sessionStorage.getItem("auth_code")) {
    return true;
  } else {
    return false;
  }
}

export function isVolunteerAuthenticated(): boolean {
  if (sessionStorage.getItem("volunteer_token") != null) {
    return true;
  } else {
    return false;
  }
}

var _isSuperadmin: boolean;
export function isSuperadmin(): boolean {
  if (_isSuperadmin === undefined) {
    const token = sessionStorage.getItem("admin_token")
    if (!token) {
      _isSuperadmin = false;
    } else {
      const decoded: any = jwtDecode(token)
      if (!decoded) {
        _isSuperadmin = false;
      }
      _isSuperadmin = decoded.role === 'superadmin'
    }
  }
  return _isSuperadmin
}

var _isCentralAdmin: boolean;
export function isCentralAdmin(): boolean {
  if (_isCentralAdmin === undefined) {
    const token = sessionStorage.getItem("admin_token")
    if (!token) {
      _isCentralAdmin = false;
    } else {
      const decoded: any = jwtDecode(token)
      if (!decoded) {
        _isCentralAdmin = false;
      }
      _isCentralAdmin = decoded.role === 'superadmin' || decoded.churches?.includes("8f4c6a26b91c743ac3168a8f7c022e2b")
    }
  }
  return _isCentralAdmin
}

export function getApiInstance(): AxiosInstance {
  const payload = {
    baseURL: process.env.REACT_APP_ACCOUNT_API_URL,
  } as AxiosRequestConfig<any>;
  const token = sessionStorage.getItem("auth_code");
  if (token) {
    // jika ada token di session
    payload.headers = {
      authorization: "Bearer " + token,
    };
  }
  const instance = axios.create(payload);
  return instance;
}

export function getAdminApiInstance(): AxiosInstance {
  const payload = {
    baseURL: process.env.REACT_APP_ACCOUNT_API_URL,
  } as AxiosRequestConfig<any>;
  const token = sessionStorage.getItem("admin_token");
  if (token) {
    // jika ada token di session
    payload.headers = {
      authorization: "Bearer " + token,
    };
  }
  const instance = axios.create(payload);
  return instance;
}

export function getOperatorApiInstance(): AxiosInstance {
  const payload = {
    baseURL: process.env.REACT_APP_ACCOUNT_API_URL,
  } as AxiosRequestConfig<any>;
  const token = sessionStorage.getItem("operator_token");
  if (token) {
    // jika ada token di session
    payload.headers = {
      authorization: "Bearer " + token,
    };
  }
  const instance = axios.create(payload);
  return instance;
}

export function getVolunteerApiInstance(): AxiosInstance {
  const payload = {
    baseURL: `${process.env.REACT_APP_ACCOUNT_API_URL}/volunteer`,
  } as AxiosRequestConfig<any>;
  const token = sessionStorage.getItem("volunteer_token");
  if (token) {
    // jika ada token di session
    payload.headers = {
      authorization: "Bearer " + token,
    };
  }
  const instance = axios.create(payload);
  return instance;
}

export async function login(authCode: string) {
  const instance = getApiInstance();
  try {
    const formData = new FormData();
    formData.set("auth_code", authCode);
    const rawResponse = await instance.post("admin/auth", formData);
    const response: ApiResponse<ApiAuthResponse> = rawResponse.data;
    sessionStorage.setItem("admin_token", response.token!);
    localStorage.setItem("person_id", response.result.mygms_id);
    return true;
  } catch (err) {
    console.error("Login failed");
    console.error(err);
    return false;
  }
}

export async function operatorLogin(authCode: string) {
  const instance = getOperatorApiInstance();
  try {
    const formData = new FormData();
    formData.set("auth_code", authCode);
    const rawResponse = await instance.post("operator/auth", formData);
    console.log(rawResponse.data);
    const response: ApiResponse<ApiAuthResponse> = rawResponse.data;
    sessionStorage.setItem("operator_token", response.token!);
    localStorage.setItem("person_id", response.result.mygms_id);
    return true;
  } catch (err) {
    console.error("Login failed");
    console.error(err);
    return false;
  }
}

export async function volunteerLogin(authCode: string) {
  const instance = getVolunteerApiInstance();
  try {
    const formData = new FormData();
    formData.set("auth_code", authCode);
    const rawResponse = await instance.post("auth", formData);
    console.log(rawResponse.data);
    const response: ApiResponse<ApiAuthResponse> = rawResponse.data;
    sessionStorage.setItem("volunteer_token", response.token!);
    localStorage.setItem("person_id", response.result.mygms_id);
    return true;
  } catch (err) {
    console.error("Login failed");
    console.error(err);
    return false;
  }
}

export async function logout() {
  sessionStorage.removeItem("admin_token");
  localStorage.removeItem("person_id");
  window.location.href = "/";
}

export function getFormattedPrice(price?: number) {
  if (price) {
    return Intl.NumberFormat("id").format(price);
  } else {
    return "-";
  }
}

// Labeling status pembayaran
export const getBadgeType = (status: string) => {
  switch (status) {
    case "EXPIRED":
    case "CANCELED":
      return "bg-danger";
    case "WAITING":
      return "bg-warning";
    case "PAID":
      return "bg-success";
    default:
      return "bg-secondary";
  }
};

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export function hasOnlineSession() {
  return process.env.REACT_APP_HAS_ONLINE_SESSION === "true"
}

export function getContactUsNumber() {
  return process.env.REACT_APP_CONTACT_US_NUMBER!;
}
export function getFormattedContactUsNumber() {
  const phoneNumber = getContactUsNumber();
  const formattedNumber = `+${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
    2,
    5
  )}-${phoneNumber.slice(5, 9)}-${phoneNumber.slice(9)}`;
  return formattedNumber;
}