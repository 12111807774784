import React from "react";

export default function LoadingScreenWidget() {
    return (
        <div id="loadingContainer">
            <div className="inline-block">
                <div className="typewriter">
                    <h3
                        id="loadingText"
                        className="text-white text-3xl font-bold"
                    >
                        LOADING...
                    </h3>
                </div>
            </div>
            <div id="loadingObject1"></div>
            <div id="loadingObject2"></div>
            <div id="loadingObject3"></div>
        </div>
    );
}
