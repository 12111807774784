import React from "react";
import { XCircleIcon } from "@heroicons/react/24/outline";

type Props = {
    message: string;
    description?: string;
};

type AlertProps = {
    label?: string;
    children?: any;
    isBold?: boolean;
};

export function AlertDangerWidget({ message, description }: Props) {
    return (
        <div className="rounded-md bg-red-50 p-4 mt-4">
            <div className="flex">
                <div className="flex-shrink-0">
                    <XCircleIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                        {message}
                    </h3>
                    {description && (
                        <div className="mt-2 text-sm text-red-700">
                            {description}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export const AlertInfo = ({ label, children, isBold }: AlertProps) => {
    return (
        <div className="alert shadow-lg mb-1 bg-zinc-100">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="stroke-info shrink-0 w-6 h-6"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
            </svg>
            {!children && <span>{isBold ? <b>{label}</b> : label}</span>}
            {children}
        </div>
    );
};
