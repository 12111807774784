import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { feedbackSchema } from "../../interfaces/schemas";
import { BaseFormGroup } from "./Form";
import { useIntl } from "react-intl";
import RadioGroup, { RadioOption } from "./RadioGroup";
import useMinistries from "../../utils/useMinistries";
import {
    Feedback,
    MinistryDivision,
    UserRegistrant,
} from "../../interfaces/interfaces";
import { getApiInstance } from "../../utils/helper";
import Swal from "sweetalert2";

type Props = {
    registrant: UserRegistrant;
    feedback?: Feedback;
};

function FeedbackWidget({ registrant, feedback }: Props) {
    const intl = useIntl();
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors, isDirty },
    } = useForm({
        resolver: yupResolver(feedbackSchema),
        defaultValues: feedback
            ? {
                  content: feedback.content,
                  interested_in_ministry: feedback.interested_in_ministry ?? [],
                  is_baptized: feedback.is_baptized ? "Y" : "N",
                  is_volunteer: feedback.is_volunteer ? "Y" : "N",
              }
            : undefined,
    });
    const { query } = useMinistries();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        Swal.showLoading();
        const instance = getApiInstance();
        try {
            await instance.post("/me/feedback", data);
            reset({ ...data });
            Swal.fire({
                icon: "success",
                text: "Terima kasih atas feedback Anda.",
            });
        } catch (exc) {
            Swal.fire({
                title: "Something went wrong!",
                text: "Terjadi kesalahan! Silahkan coba beberapa saat lagi.",
                icon: "error",
            });
        } finally {
            setIsLoading(false);
        }
    };

    const isVolunteer = watch("is_volunteer");
    const content = watch("content");
    const selectedMinistries = watch("interested_in_ministry");

    const isVolunteerOptions: RadioOption[] = [
        {
            label: "Sudah",
            value: "Y",
            options: register("is_volunteer"),
        },
        {
            label: "Belum",
            value: "N",
            options: register("is_volunteer"),
        },
    ];
    const isBaptizedOptions: RadioOption[] = [
        {
            label: "Sudah",
            value: "Y",
            options: register("is_baptized"),
        },
        {
            label: "Belum",
            value: "N",
            options: register("is_baptized"),
        },
    ];

    const handleSelectMinistry = (value: string) => {
        if (value !== "") {
            let _ministries = selectedMinistries ?? [];
            if (selectedMinistries?.includes(value)) {
                // kalau sudah pernah ada, hapus value sebelumnya
                const index = _ministries.indexOf(value);
                _ministries.splice(index, 1);
            } else {
                // kalau belum ada tambahkan baru
                _ministries?.push(value);
            }
            setValue("interested_in_ministry", _ministries, {
                shouldDirty: true,
            });
        }
    };

    const removeSelectedMinistry = (id: string) => {
        let _ministries = selectedMinistries ?? [];
        if (_ministries.length === 0) return;
        const index = _ministries.indexOf(id);
        if (index >= 0) {
            _ministries.splice(index, 1);
        }
        setValue("interested_in_ministry", _ministries, {
            shouldDirty: true,
        });
    };

    const renderMinistryOptions = (items: MinistryDivision[]) => {
        const ministries = [...new Set(items.map((o) => o.ministry))];
        return (
            <div className="relative">
                {selectedMinistries?.map((id) => {
                    const ministry = items.find((item) => item.id === id);
                    if (!ministry) return <></>;
                    return (
                        <div
                            key={`badge-selected-ministry-${ministry.id}`}
                            className="badge badge-warning text-white"
                        >
                            {ministry?.division}
                            <button
                                className="btn btn-sm btn-circle btn-ghost"
                                onClick={() =>
                                    removeSelectedMinistry(ministry.id)
                                }
                            >
                                ✕
                            </button>
                        </div>
                    );
                })}
                <select
                    className="w-full select select-sm select-bordered text-black mt-2"
                    onChange={(e) => handleSelectMinistry(e.target.value)}
                    value=""
                    hidden={
                        selectedMinistries && selectedMinistries.length >= 2
                    }
                >
                    <option value="">-- Pilih maksimal 2 --</option>
                    {ministries.map((ministry) => {
                        return (
                            <optgroup
                                key={`option-group-${ministry}`}
                                label={ministry}
                            >
                                {items
                                    .filter(
                                        (item) => item.ministry === ministry
                                    )
                                    .map((row) => {
                                        return (
                                            <option
                                                key={`option-${row.id}`}
                                                value={row.id}
                                            >
                                                {row.division}
                                            </option>
                                        );
                                    })}
                            </optgroup>
                        );
                    })}
                </select>
            </div>
        );
    };

    return (
        <div>
            {/* <div className="card">
                <div className="card-body">
                    <BaseFormGroup label="Nama">
                        {registrant.name}
                    </BaseFormGroup>
                    <BaseFormGroup label="E-mail">
                        {registrant.email}
                    </BaseFormGroup>
                    <BaseFormGroup label="Phone Number">
                        {registrant.cell_phone}
                    </BaseFormGroup>
                </div>
            </div> */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card">
                    <div className="card-body">
                        <BaseFormGroup
                            label={intl.formatMessage({
                                id: "isVolunteer",
                                defaultMessage: "1. Apakah sudah melayani?",
                            })}
                            error={errors.is_volunteer}
                        >
                            <RadioGroup options={isVolunteerOptions} />
                        </BaseFormGroup>
                        <BaseFormGroup
                            hidden={isVolunteer !== "N"}
                            label={intl.formatMessage({
                                id: "interested_in_ministry",
                                defaultMessage:
                                    "Tertarik pelayanan di bidang apa",
                            })}
                            error={errors.interested_in_ministry}
                        >
                            {query.isFetched &&
                                query.data &&
                                renderMinistryOptions(query.data)}
                        </BaseFormGroup>
                        <BaseFormGroup
                            label={intl.formatMessage({
                                id: "isBaptized",
                                defaultMessage:
                                    "2. Apakah sudah dibaptis selam?",
                            })}
                            error={errors.is_baptized}
                        >
                            <RadioGroup options={isBaptizedOptions} />
                        </BaseFormGroup>
                        <BaseFormGroup
                            label={intl.formatMessage({
                                id: "content",
                                defaultMessage:
                                    "3. Janji/visi apa yang didapat/dialami selama mengikuti AOG Conference?",
                            })}
                            error={errors.content}
                        >
                            <div>
                                <textarea
                                    className="w-full textarea textarea-bordered texarea-lg text-black leading-5 min-h-[250px]"
                                    placeholder="Silahkan isi feedback Anda disini..."
                                    {...register("content")}
                                />

                                <div className="label">
                                    <span className="label-text-alt"></span>
                                    <span className="label-text-alt text-white">
                                        {content ? content.length : 0} karakter
                                    </span>
                                </div>
                            </div>
                        </BaseFormGroup>
                    </div>
                </div>
                <div className="form-group">
                    <button
                        disabled={isLoading || !isDirty}
                        className="btn w-100 btn-submit"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
}

export function FeedbackLink() {
    return (
        <>
            <button className="btn btn-warning text-lg w-full text-white">
                Feedback untuk AOG Conference
            </button>
        </>
    );
}

export default FeedbackWidget;
