import "./admin.scss";
import React, { useEffect, useState } from "react";
import Header from "./partials/Header";
import { Registrant } from "../interfaces/interfaces";
import moment from "moment";
import DataTable, { Alignment } from "react-data-table-component";
import Swal from "sweetalert2";
import { utils, writeFileXLSX } from "xlsx";
import useAdminEventItems from "../utils/useAdminEventItems";
import { Link, useLocation } from "react-router-dom";
import useRegistrants from "../utils/useRegistrants";

function AdminRegistrantPage() {
    const query = new URLSearchParams(useLocation().search);
    const itemId = query.get("item_id");
    const { items, isLoading } = useAdminEventItems();
    // const [registrants, setRegistrants] = useState([] as Registrant[]);
    const { data: registrants, isLoading: isLoadingRegistrants } =
        useRegistrants(itemId ? itemId : items ? items[0].id : "");
    const [filterText, setFilterText] = useState("");
    const [activeTab, setActiveTab] = useState("");
    const filteredRegistrants = registrants?.filter((registrant) => {
        let _filterText = filterText.toLowerCase();

        return (
            registrant.name.toLowerCase().includes(_filterText) ||
            registrant.church_name.toLowerCase().includes(_filterText) ||
            registrant.bill_no.toLowerCase().includes(_filterText) ||
            (_filterText === "online"
                ? registrant.service_type === "online"
                : registrant.ticket_church_name
                      .toLowerCase()
                      .includes(_filterText))
        );
    });
    // init
    useEffect(() => {
        if (!items || items.length === 0) {
            return;
        }
        if (itemId) {
            setActiveTab(itemId);
        }
        (async () => {
            try {
                // const filterItemId = itemId ?? items[0].id;
                // const instance = getAdminApiInstance();
                // const apiResult = await instance.get("admin/registrants", {
                //     params: {
                //         item_id: filterItemId,
                //     },
                // });
                // setRegistrants(apiResult.data.result);
            } catch (exc: any) {
                Swal.fire("Error", "Something went wrong!", "error");
            }
        })();
    }, [items, itemId]);
    //   modul export
    const handleExportData = async () => {
        const items = filteredRegistrants;
        // generate excel
        const worksheet = utils.json_to_sheet(
            items.map((o) => {
                return {
                    bill_no: o.bill_no,
                    name: o.name,
                    ticket: o.ticket_church_name,
                    ticket_status: o.ticket_status,
                    ticket_scan_at: o.updated_at
                        ? moment(o.updated_at).format("YYYY-MM-DD HH:mm:ss")
                        : "",
                    ticket_scan_operator: o.operator,
                    church_origin: o.church_name,
                    registered_at: moment(o.created_at).format(
                        "YYYY-MM-DD HH:mm:ss"
                    ),
                };
            })
        );
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Sheet1");
        // create file download
        const filename =
            "export - " +
            moment().utc().add(7, "hours").format("YYYYMMDD HHmmss") +
            ".xlsx";
        worksheet["!cols"] = [{ wch: 12 }, { wch: 20 }];
        writeFileXLSX(workbook, filename);
    };
    //   search filter
    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <input
                type="text"
                placeholder="Search..."
                onChange={(e) => setFilterText(e.target.value)}
                value={filterText}
            />
        );
    }, [filterText]);
    // schema column
    const columns = [
        {
            name: "Bill No",
            selector: (row: Registrant) => row.bill_no,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row: Registrant) => row.name,
            wrap: true,
            sortable: true,
        },
        // {
        //     name: "Ticket",
        //     selector: (row: Registrant) =>
        //         row.service_type === "online"
        //             ? "Online"
        //             : row.ticket_church_name,
        //     sortable: true,
        // },
        {
            name: "Ticket Status",
            sortable: true,
            omit: activeTab === "760d71bf122b4ff3a59b7835348f71f7",

            selector: (row: Registrant) => {
                return row.ticket_status ?? "-";
            },
            format: (row: Registrant) => {
                switch (row.ticket_status) {
                    case "active":
                        return (
                            <span className="badge badge-success">
                                {row.ticket_status}
                            </span>
                        );
                    case "used":
                        return (
                            <div>
                                <span className="badge badge-secondary">
                                    {row.ticket_status}
                                </span>
                            </div>
                        );
                    default:
                        return "";
                }
            },
        },
        {
            name: "Operator Scan",
            selector: (row: Registrant) => row.operator ?? "",
            wrap: true,
            format: (row: Registrant) => {
                return (
                    <div>
                        {row.operator ?? ""}
                        <br />
                        {row.updated_at && (
                            <>
                                at{" "}
                                {moment(row.updated_at).format(
                                    "YY-MM-DD HH:mm Z"
                                )}
                            </>
                        )}
                    </div>
                );
            },
        },
        {
            name: "Pickup Location",
            selector: (row: Registrant) => row.toolkit_pickup_location ?? "",
        },
        {
            name: "Church Origin",
            selector: (row: Registrant) => row.church_name,
            sortable: true,
        },
        {
            name: "Registered At",
            selector: (row: Registrant) =>
                moment(row.created_at).format("YYYY-MM-DD"),
            sortable: true,
        },
    ];

    const renderNavs = () => {
        return (
            <ul className="nav nav-tabs">
                {items?.map((item, i) => {
                    const _isActive =
                        activeTab === "" && i === 0
                            ? true
                            : activeTab === item.id;
                    return (
                        <li className="nav-item" key={`nav-item-${item.id}`}>
                            <Link
                                className={
                                    `nav-link ` + (_isActive ? "active" : "")
                                }
                                replace={true}
                                to={"/admin/registrants?item_id=" + item.id}
                            >
                                {item.service_type === "online"
                                    ? "Online"
                                    : item.name}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <div>
            <Header currentPage={"admin/registrants"} />
            <div
                id="mainAdmin"
                className="mx-auto max-w-9xl px-4 sm:px-6 lg:px-8"
            >
                {/* content */}
                <div className="app-content content">
                    <div className="content-wrapper">
                        <div className="content-header row">
                            <div className="content-header-left">
                                <div className="breadcrumbs-top">
                                    <div className="col-12">
                                        <h2 className="content-header-title">
                                            Daftar Scan
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-body">
                            <div className="mb-2 mt-2">
                                <button
                                    className="btn btn-primary"
                                    onClick={handleExportData}
                                >
                                    Export Excel
                                </button>
                            </div>
                            {isLoading && <div>Loading...</div>}
                            {!isLoading && (
                                <div className="card">
                                    {renderNavs()}
                                    {isLoadingRegistrants && (
                                        <div className="text-center">
                                            Loading data...
                                        </div>
                                    )}
                                    {!isLoadingRegistrants && (
                                        <DataTable
                                            columns={columns}
                                            data={filteredRegistrants}
                                            striped
                                            pagination
                                            responsive
                                            subHeader
                                            subHeaderAlign={Alignment.RIGHT}
                                            subHeaderComponent={
                                                subHeaderComponentMemo
                                            }
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminRegistrantPage;
