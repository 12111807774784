import React, { useMemo, useState } from "react";
import { UserRegistrant, Workshop } from "../../interfaces/interfaces";
import useWorkshops from "../../utils/useWorkshops";
import RadioGroup from "../widgets/RadioGroup";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
import { getApiInstance } from "../../utils/helper";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import FeedbackWidget from "../widgets/FeedbackWidget";
import useFeedback from "../../utils/useFeedback";

type Props = {
    registrant: UserRegistrant;
};

const queryCacheKey = "workshops";
function WorkshopsCard({ registrant }: Props) {
    const intl = useIntl();
    const churchId = useMemo(() => {
        const items = registrant.registration_status?.transaction.items;
        if (items && items.length > 0) {
            // ambil salah 1 saja
            return items[0].church_id ?? "";
        } else {
            return "";
        }
    }, [registrant.registration_status?.transaction.items]);
    const { query } = useWorkshops(queryCacheKey, churchId);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [selectedWorkshop, setSelectedWorkshop] = useState(
        registrant.workshop_id ?? ""
    );
    const { query: queryFeedback } = useFeedback(
        query.isFetched && query.data?.length === 0
    );

    const handleWorkshopChange = (item: Workshop) => {
        // hanya boleh pilih jika quota masih tersedia
        if (item.left_capacity <= 0) return;
        Swal.fire({
            title: intl.formatMessage(
                {
                    id: "workshopConfirmation",
                    defaultMessage: "Pilih workshop {name}?",
                },
                {
                    name: item.name,
                }
            ),
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({
                id: "yes",
                defaultMessage: "Ya",
            }),
            cancelButtonText: intl.formatMessage({
                id: "no",
                defaultMessage: "Tidak",
            }),
            preConfirm: () => {
                const instance = getApiInstance();
                return instance.post(`/me/workshops/${item.id}/register`);
            },
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
        })
            .then((res) => {
                if (res.isConfirmed) {
                    queryClient.invalidateQueries(
                        `${queryCacheKey}-${churchId}`
                    );
                    if (res.value?.status === 202 && res.value.data.error) {
                        Swal.fire({
                            title: res.value.data.error,
                            icon: "warning",
                        });
                    } else {
                        setSelectedWorkshop(item.id);
                        navigate(0);
                        Swal.showLoading();
                    }
                }
            })
            .catch((exc) => {
                const res = exc.response;
                const errMessage = res.data.error ?? "Something went wrong!";
                Swal.fire("Error", errMessage, "error");
            });
    };

    const getDescription = (item: Workshop) => {
        let result;
        switch (item.name) {
            case "Workshop Building & Infrastructure":
                result = (
                    <div>
                        <div className="text-xs hyphens-auto">
                            Tim Pengembangan Gereja (TPG), General Affair (GA),
                            Finance
                            <br />
                            <br />
                            Mengenal kompleksitas membangun gedung gereja dan
                            infrastrukturnya dalam workshop ini. Pelajari
                            prinsip-prinsip kunci dan pertimbangan untuk
                            menciptakan ruang yang mendorong komunitas dan
                            ibadah yang keberlanjutan.
                        </div>
                        Quota: {item.left_capacity}
                    </div>
                );
                break;
            case "Workshop People & Mission":
                result = (
                    <div>
                        <div className="text-xs hyphens-auto">
                            Grace Mission University (GMU), Mawar Sharon Peduli
                            (MSP), Pastoral, Family Life & Counseling (FLC),
                            Human Capital (HC)
                            <br />
                            <br />
                            Temukan strategi dalam mewadahi dan memperlengkapi
                            jemaat yang bertumbuh dengan semangat misi dalam
                            pergerakan gereja secara keseluruhan.
                        </div>
                        Quota: {item.left_capacity}
                    </div>
                );
                break;
            case "Workshop Production":
                result = (
                    <div>
                        <div className="text-xs hyphens-auto">
                            Mengenal proses kreativitas dan profesionalitas
                            produksi dalam menangkap dan membagikan pekerjaan
                            Tuhan melalui gereja-Nya. Mencakup teknik-teknik
                            penting, peralatan, dan tips untuk menciptakan
                            pengalaman ibadah yang berkesan dan menarik.
                        </div>
                        Quota: {item.left_capacity}
                    </div>
                );
                break;
            case "Workshop Media":
                result = (
                    <div>
                        <div className="text-xs hyphens-auto">
                            Communication (Comm), Media Rajawali Indonesia
                            (MRI), Information and Communication Technology
                            (ICT)
                            <br />
                            <br />
                            Jelajahi strategi komunikasi digital yang efektif
                            dengan cara memanfaatkan teknologi untuk memperkuat
                            pesan pekabaran Injil melalui media.
                        </div>
                        Quota: {item.left_capacity}
                    </div>
                );
                break;
            default:
                result = <div></div>;
                break;
        }
        return result;
    };
    if (
        query.isFetching ||
        (query.data?.length === 0 && queryFeedback.isFetching)
    ) {
        return <></>;
    } else if (queryFeedback.isFetched) {
        return (
            <>
                <h3 className="mt-4 text-3xl text-white font-bold text-center">
                    <span className="text-3xl text-white font-bold text-center between-arrow">
                        FEEDBACK
                    </span>
                </h3>
                <FeedbackWidget
                    registrant={registrant}
                    feedback={queryFeedback.data}
                />
            </>
        );
    } else {
        return (
            <div className="mt-4">
                <h3 className="text-3xl text-white font-bold text-center">
                    <span className="text-3xl text-white font-bold text-center between-arrow">
                        WORKSHOP
                    </span>
                </h3>
                <div className="card">
                    <div className="card-body">
                        {query.isFetched && query.data && (
                            <RadioGroup
                                options={query.data.map((item) => {
                                    return {
                                        label: item.name,
                                        description: getDescription(item),
                                        value: item.id,
                                        isChecked: selectedWorkshop === item.id,
                                        disabled: item.left_capacity <= 0,
                                        onChange: () =>
                                            handleWorkshopChange(item),
                                    };
                                })}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default WorkshopsCard;
