import useSWR from "swr";
import { Registrant } from "../interfaces/interfaces";
import { getAdminApiInstance } from "./helper";

// const filterItemId = itemId ?? items[0].id;
// const instance = getAdminApiInstance();
// const apiResult = await instance.get("admin/registrants", {
//     params: {
//         item_id: filterItemId,
//     },
// });

// setRegistrants(apiResult.data.result);

const fetcher = (url: string) => {
    const instance = getAdminApiInstance();
    return instance.get(url).then((res) => res.data.result);
};

export default function useRegistrants(itemId: string) {
    var { data, error, isLoading } = useSWR(
        itemId ? `admin/registrants?item_id=${itemId}` : null,
        fetcher
    );

    return {
        data: data as Registrant[],
        isLoading,
        isError: error,
    };
}
