/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useMemo } from "react";
import usePickupLocations from "../../utils/usePickupLocations";
import { BaseFormGroup } from "../widgets/Form";
import RadioGroup, { RadioOption } from "../widgets/RadioGroup";
import { useIntl } from "react-intl";

type Props = {
    churchId: string;
    stateValue: string;
    setStateValue: React.Dispatch<React.SetStateAction<string>>;
};

export default function PickupLocationCard({
    churchId,
    stateValue,
    setStateValue,
}: Props) {
    const { locations, isLoading, isError } = usePickupLocations(churchId);
    const intl = useIntl();

    const options: RadioOption[] = useMemo(() => {
        if (!isLoading && locations) {
            // default checked bila hanya ada 1 value saja
            if (locations.length === 1 && locations[0].id !== stateValue) {
                setStateValue(locations[0].id);
            }
            return locations?.map((location) => {
                return {
                    label: location.name,
                    value: location.id,
                    isChecked: stateValue === location.id,
                    onChange: (e) => {
                        setStateValue(e.target.value);
                    },
                    description: location.address ?? <></>,
                };
            });
        } else {
            return [];
        }
    }, [isLoading, locations, setStateValue, stateValue]);

    return (
        <>
            {!isLoading && !isError && (
                <div>
                    <BaseFormGroup
                        label={intl.formatMessage({
                            id: "toolkit.select",
                            defaultMessage:
                                "Silahkan pilih lokasi pengambilan toolkit",
                        })}
                    >
                        <RadioGroup options={options} />
                    </BaseFormGroup>
                </div>
            )}
        </>
    );
}
