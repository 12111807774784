import React from "react";
import { Disclosure, Menu } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { classNames, isCentralAdmin } from "../../utils/helper";
import { Link } from "react-router-dom";

type Props = {
    currentPage: string;
};

export default function Header(props: Props) {
    const menus = [
        {
            url: "admin/dashboard",
            text: "Dashboard",
        },
        {
            url: "admin/dashboard/workshop",
            text: "Workshop Dashboard",
        },
        {
            url: "admin",
            text: "Konfirmasi Pembayaran",
        },
        {
            url: "admin/quota",
            text: "Quota",
        },
        {
            url: "admin/registrants",
            text: "Peserta",
        },
        {
            url: "admin/registrants/by-tickets",
            text: "Summary Pembayaran",
        },
        {
            url: "admin/volunteers",
            text: "Volunteer Registration",
            hidden: !isCentralAdmin(),
        },
    ];

    const renderMobileLinks = () => {
        return menus
            .filter((menu) => !menu.hidden)
            .map((menu) => {
                const isActive = props.currentPage === menu.url;
                return (
                    <Disclosure.Button
                        as="a"
                        href={`/${menu.url}`}
                        className={classNames(
                            isActive
                                ? "border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
                                : "border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700",
                            "block border-l-4"
                        )}
                    >
                        {menu.text}
                    </Disclosure.Button>
                );
            });
    };

    const renderLinks = () => {
        return menus
            .filter((menu) => !menu.hidden)
            .map((menu) => {
                const isActive = props.currentPage === menu.url;
                return (
                    <Link
                        to={`/${menu.url}`}
                        className={classNames(
                            isActive
                                ? "border-indigo-500 text-gray-900"
                                : "text-gray-500 hover:border-gray-300 border-transparent hover:text-gray-700",
                            "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium "
                        )}
                    >
                        {menu.text}
                    </Link>
                );
            });
    };
    return (
        <Disclosure as="nav" className="bg-white shadow">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-16 justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    {open ? (
                                        <XMarkIcon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <Bars3Icon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                    {renderLinks()}
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                {/* Profile dropdown */}
                                <Menu as="div" className="relative ml-3">
                                    <div>
                                        <a
                                            href="/admin/logout"
                                            className={classNames(
                                                "block px-4 py-2 text-sm text-gray-700"
                                            )}
                                        >
                                            Logout
                                        </a>
                                    </div>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 pb-4 pt-2">
                            {renderMobileLinks()}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}
