import React, { useRef } from "react";
import Swal from "sweetalert2";
import { AlertInfo } from "../widgets/Alert";
import { FormattedMessage, useIntl } from "react-intl";
import {
    getContactUsNumber,
    getFormattedContactUsNumber,
} from "../../utils/helper";

type Props = {
    agreeCallback?: Function;
};

function RegistrantAgreement(props: Props) {
    const intl = useIntl();
    const agreeRef = useRef<HTMLInputElement>(null);
    const handleAgree = () => {
        if (!agreeRef.current?.checked) {
            Swal.fire(
                "",
                intl.formatMessage({
                    id: "agreementWarning",
                    defaultMessage:
                        "Silahkan membaca dan menyetujui syarat dan ketentuan yang berlaku.",
                }),
                "warning"
            );
        } else {
            if (props.agreeCallback) props.agreeCallback(true);
        }
    };
    return (
        <>
            <h3 className="font-bold text-white text-center text-4xl mb-4">
                ARMY OF GOD
                <br />
                CONFERENCE
            </h3>
            <h3 className="font-extrabold text-white text-center text-2xl header-description">
                {
                    <FormattedMessage
                        id="tnc"
                        defaultMessage="Syarat dan Ketentuan"
                    />
                }
            </h3>
            <div className="card -mx-4 sm:mx-0">
                <div className="card-body pb-0">
                    <div className="mt-3 mb-4 text-black bg-white p-3 text-sm">
                        <div className="">
                            {intl.locale === "id" && (
                                <ol className="text-left pl-4 list-decimal">
                                    <li>
                                        Setiap peserta <b>WAJIB</b> melakukan
                                        pendaftaran dan memenuhi semua
                                        persyaratan registrasi untuk mengikuti
                                        AOG Conference 2024.
                                    </li>
                                    <li>
                                        Peserta dapat melakukan pendaftaran
                                        melalui Website{" "}
                                        <b>gms.church/aogconference</b>
                                    </li>
                                    <li>
                                        Setiap peserta <b>WAJIB</b> memiliki
                                        akun pribadi pada GMS App dan sudah
                                        mengisi data diri secara <b>LENGKAP</b>.
                                    </li>
                                    <li>
                                        Harga tiket sesuai dengan kota yang dipilih saat melakukan registrasi.
                                    </li>
                                    <li>
                                        Pembayaran biaya registrasi dapat
                                        dilakukan melalui 3 metode pembayaran
                                        sebagai berikut:
                                        <b>
                                            Shopee Pay, OVO, dan BCA Virtual
                                            account
                                        </b>
                                        . Batas waktu maksimal pembayaran adalah{" "}
                                        <b>1x24 jam</b> dari waktu pendaftaran.
                                        Semua pembayaran akan dilakukan secara
                                        otomatis.
                                    </li>
                                    <li>
                                        Peserta yang melebihi batas waktu 1x24
                                        jam akan terhapus secara otomatis dan{" "}
                                        <b>WAJIB</b> mendaftar ulang pada
                                        website selama kuota masih tersedia.
                                    </li>
                                    <li>
                                        Bagi Peserta yang sudah dinyatakan
                                        ter-registrasi / pembayaran
                                        terkonfirmasi akan menerima QR Code
                                        untuk pengambilan toolkit.
                                    </li>
                                    <li>
                                        Jika terdapat hal lain yang ingin
                                        ditanyakan dapat menghubungi{" "}
                                        <b>
                                            Hotline{" "}
                                            <a
                                                href={`https://wa.me/${getContactUsNumber()}?target=_blank`}
                                            >
                                                {getFormattedContactUsNumber()}
                                            </a>
                                        </b>
                                    </li>
                                </ol>
                            )}
                            {intl.locale === "en" && (
                                <ol className="text-left pl-4 list-decimal">
                                    <li>
                                        Participants <b>MUST</b> register and
                                        meet all the registration requirements
                                        to attend the AOG Conference 2024.
                                    </li>
                                    <li>
                                        Participants can register through the
                                        Website{" "}
                                        <b>
                                            <a href="https://gms.church/aogconference">
                                                gms.church/aogconference
                                            </a>
                                        </b>
                                        .
                                    </li>
                                    <li>
                                        Every participant <b>MUST</b> have a
                                        personal account on the GMS Church App
                                        and have filled in their data{" "}
                                        <b>COMPLETELY</b>.
                                    </li>
                                    <li>
                                        Ticket prices are as follows
                                        (participants will get a toolkit, lunch
                                        & dinner on Day 2):
                                        <ul>
                                            <li>
                                                Early Bird (January 27—February
                                                11, 2024) : Rp200.000,00
                                            </li>
                                            <li>
                                                Normal Price (February 11—March
                                                9, 2024) : Rp250.000,00
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Registration fees can be paid through 3
                                        payment methods as follows:{" "}
                                        <b>
                                            Shopee Pay, OVO, and BCA Virtual
                                            account
                                        </b>
                                        . The maximum payment deadline is{" "}
                                        <b>1x24 hours</b> from the time of
                                        registration. All payments will be made
                                        automatically.
                                    </li>
                                    <li>
                                        Participants who exceed the payment
                                        deadline
                                        <b>(1x24 hours)</b> will be deleted
                                        automatically and <b>MUST</b>{" "}
                                        re-register on the website as long as
                                        the quota is still available.
                                    </li>
                                    <li>
                                        Participants who have been declared
                                        registered / payment confirmed will
                                        receive a QR Code for toolkit
                                        collection.
                                    </li>
                                    <li>
                                        If there are other things you want to
                                        ask, you can contact the Hotline{" "}
                                        <b>
                                            Hotline{" "}
                                            <a
                                                href={`https://wa.me/${getContactUsNumber()}?target=_blank`}
                                            >
                                                {getFormattedContactUsNumber()}
                                            </a>
                                        </b>
                                    </li>
                                </ol>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <p className="text-left mt-2 mb-2 text-white">
                    <label htmlFor="checkAgree">
                        <input
                            ref={agreeRef}
                            id="checkAgree"
                            className="check"
                            type="checkbox"
                        />
                        <FormattedMessage
                            id="agreementMessage"
                            defaultMessage="
                        Saya telah membaca dan menyetujui setiap ketentuan yang
                        berlaku."
                        />
                    </label>
                </p>
                <button
                    type="button"
                    onClick={handleAgree}
                    className="w-full btn btn-primary"
                >
                    <FormattedMessage
                        id="btnNext"
                        defaultMessage="Selanjutnya"
                    />
                </button>
            </div>
        </>
    );
}

export default RegistrantAgreement;
