import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";

type Props = {
    activeKey: string;
};

export default function OperatorMenu(props: Props) {
    return (
        <Navbar bg="light" className="mb-3">
            <Container fluid>
                <Nav
                    variant="tabs"
                    className="ml-auto mr-auto"
                    defaultActiveKey={`/operator${props.activeKey}`}
                >
                    <Nav.Link href="/operator">Registration</Nav.Link>
                    <Nav.Link href="/operator/histories">Histories</Nav.Link>
                    {/* <Nav.Link href="/operator/toolkit">Toolkit</Nav.Link> */}
                </Nav>
            </Container>
        </Navbar>
    );
}
