import moment from "moment";
import React from "react";
import useOperatorHistories from "../utils/useOperatorHistories";
import "./operator-page.scss";
import OperatorMenu from "./OperatorMenu";

function OperatorHistories() {
    const { items: histories, isLoading } = useOperatorHistories();

    const renderContent = () => {
        if (isLoading) {
            return <>Loading...</>;
        }
        if (!histories || histories.length === 0) {
            return (
                <div className="text-center">
                    <i>Belum ada history scan</i>
                </div>
            );
        }
        // grouping berdasarkan tanggal
        const dates = histories
            .map((o) => moment(o.created_at).format("YYYY-MM-DD"))
            .filter((value, i, self) => self.indexOf(value) === i);
        return (
            <>
                {dates.map((group) => {
                    const filteredHistories = histories.filter(
                        (o) =>
                            moment(o.created_at).format("YYYY-MM-DD") === group
                    );
                    const confirmedTransactions = filteredHistories.filter(
                        (o) => o.notes.includes("Confirm payment")
                    ).length;
                    const canceledransactions = filteredHistories.filter((o) =>
                        o.notes.includes("Cancel registration")
                    ).length;
                    return (
                        <div key={`date-${group}`} className="mb-4">
                            <h4>
                                {group} ({confirmedTransactions} confirmed,{" "}
                                {canceledransactions} canceled)
                            </h4>
                            <ul className="list-group">
                                {filteredHistories.map((o, i) => {
                                    var cardStyle: any = {};
                                    if (o.notes.includes("Confirm payment")) {
                                        cardStyle.backgroundColor = "#c5ffc5";
                                    } else if (
                                        o.notes.includes("Cancel registration")
                                    ) {
                                        cardStyle.backgroundColor = "#ffc2c2";
                                    }
                                    return (
                                        <li
                                            key={`histories-${o.id}`}
                                            className="list-group-item"
                                            style={cardStyle}
                                        >
                                            <b>{i + 1}</b>.{" "}
                                            {moment(o.created_at).format(
                                                "HH:mm"
                                            )}
                                            : {o.notes}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <div id="mainOperator">
            <OperatorMenu activeKey="/histories" />
            <div className="app-content content">{renderContent()}</div>
        </div>
    );
}

export default OperatorHistories;
