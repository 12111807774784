import { useEffect, useState } from "react";
import { EventItem } from "../interfaces/interfaces";
import { getApiInstance } from "./helper";
import * as Sentry from "@sentry/react";

type Props = {
  show_all: boolean;
};

export default function useEventItems(props: Props) {
  const [items, setItems] = useState<EventItem[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const getData = async (signal: AbortSignal) => {
      const instance = getApiInstance();
      try {
        const rawResponse = await instance.get("/items", {
          params: {
            show_all: props.show_all ? 1 : 0,
          },
          signal: signal,
        });
        setItems(rawResponse.data.result);
      } catch (error: any) {
        Sentry.captureException(error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    /**
     * Abort signal agar request api sebelumnya di cancel bila memerlukan reload dan request belum selesai
     */
    const controller = new AbortController();
    getData(controller.signal);
    return () => {
      controller.abort();
    };
  }, [props.show_all]);

  return { items, isLoading, isError };
}
