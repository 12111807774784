import * as yup from "yup";

export const feedbackSchema = yup.object().shape({
    is_volunteer: yup.string().oneOf(["Y", "N"]).required("Silahkan isi apakah Anda sudah tergabung dalam pelayanan."),
    interested_in_ministry: yup.array().of(
        yup.string()
    ).when("is_volunteer", {
        is: "N",
        then: (schema) => schema.min(1, "Silahkan pilih minimal 1").required("Silahkan isi di bidang apa Anda tertarik untuk melayani.")
    }),
    is_baptized: yup.string().oneOf(["Y", "N"]).required("Silahkan isi apakah Anda sudah pernah dibaptis selam."),
    content: yup.string().trim().min(300).transform((value) => value.trim()).required("Silahkan isi feedback Anda.")
});