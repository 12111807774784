import { useEffect, useState } from "react";
import { EventItem } from "../interfaces/interfaces";
import { getVolunteerApiInstance } from "./helper";
import * as Sentry from "@sentry/react";

export default function useEventVolunteerItems() {
    const [items, setItems] = useState<EventItem[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const getData = async (signal: AbortSignal) => {
            const instance = getVolunteerApiInstance();
            try {
                const rawResponse = await instance.get("/items", {
                    signal: signal,
                });
                setItems(rawResponse.data.result);
            } catch (error: any) {
                Sentry.captureException(error);
                setIsError(true);
            } finally {
                setIsLoading(false);
            }
        };
        /**
         * Abort signal agar request api sebelumnya di cancel bila memerlukan reload dan request belum selesai
         */
        const controller = new AbortController();
        getData(controller.signal);
        return () => {
            controller.abort();
        };
    }, []);

    return { items, isLoading, isError };
}
