import React, { useState } from "react";
import { BaseFormGroup } from "./widgets/Form";
import useVolunteerRegistrant from "../utils/useVolunteerRegistrant";
// import { atom, useRecoilValue } from "recoil";
// import SelectMinistry from "./partials/SelectMinistry";
import Swal from "sweetalert2";
import { getVolunteerApiInstance } from "../utils/helper";
import useEventVolunteerItems from "../utils/useEventVolunteerItems";
import VolunteerRegistrationDetail from "./partials/VolunteerRegistrationDetail";
import { VolunteerRegistrant } from "../interfaces/interfaces";
// import { XCircleIcon } from "@heroicons/react/24/outline";
import { AlertDangerWidget } from "./widgets/Alert";

// const useMinistryState = atom<string>({
//     key: "useMinistryState",
//     default: "",
// });

function VolunteerPage() {
    const { data: registrant, isLoading, isError } = useVolunteerRegistrant();
    // const ministry = useRecoilValue(useMinistryState);
    const { items, isLoading: isLoadingItems } = useEventVolunteerItems();
    const [formRequest, setFormRequest] = useState({
        loading: false,
        canSubmit: true,
    });
    const ticket =
        items && registrant
            ? items.find((item) => item.church_id === registrant?.church_id)
            : undefined;

    const isRegistered = (registrant: VolunteerRegistrant) => {
        return (
            registrant.registration_status?.registered &&
            registrant.registration_status?.transaction?.status !== "EXPIRED" &&
            registrant.registration_status?.transaction?.status !== "CANCELED"
        );
    };

    const handleRegister = async () => {
        setFormRequest({ canSubmit: false, loading: false });
        const payload = {
            shirt_size: "",
            ministry: "",
            item_id: ticket?.id,
        };
        setFormRequest({ canSubmit: true, loading: false });
        Swal.fire({
            title: "Confirmation",
            text: `Confirm registration?`,
            showDenyButton: true,
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                const instance = getVolunteerApiInstance();
                return instance.post("/register", payload);
            },
        })
            .then((res) => {
                if (res.isConfirmed) {
                    const value = res.value?.data;
                    if (value.result && value.result.redirect_url) {
                        window.location.href = value.result.redirect_url;
                    }
                }
            })
            .catch((err) => {
                const res = err.response;
                const message = res.error;
                if (!message) {
                    Swal.fire("Error", "Something went wrong!", "error");
                } else {
                    Swal.fire("Error", message, "error");
                }
            })
            .finally(() => {
                setFormRequest({ canSubmit: true, loading: false });
            });
    };

    const renderForm = () => {
        if (!ticket) {
            return (
                <div>
                    <i>
                        Sorry, no volunteer ticket is available for your church.
                    </i>
                </div>
            );
        } else if (registrant) {
            return (
                <div className="content-wrapper">
                    {registrant.registration_status?.registered &&
                        (registrant.registration_status?.transaction.status ===
                            "EXPIRED" ||
                            registrant.registration_status?.transaction
                                .status === "CANCELED") && (
                            <div className="mt-4">
                                <AlertDangerWidget
                                    message={`Your registration is ${registrant.registration_status?.transaction.status}`}
                                />
                            </div>
                        )}

                    {/* Profile */}
                    <div className="card">
                        <div className="card-body">
                            <BaseFormGroup label="Name">
                                {registrant.name}
                            </BaseFormGroup>
                            <BaseFormGroup label="Phone Number">
                                {registrant.cell_phone}
                            </BaseFormGroup>
                            <BaseFormGroup label="Church Name">
                                {registrant.church_name}
                            </BaseFormGroup>
                            <BaseFormGroup label="E-Mail">
                                {registrant.email}
                            </BaseFormGroup>
                        </div>
                    </div>
                    <div hidden={!isRegistered(registrant)}>
                        {isRegistered(registrant) && (
                            <VolunteerRegistrationDetail
                                registrant={registrant}
                            />
                        )}
                    </div>
                    {!isRegistered(registrant) && (
                        <div className="form-group">
                            <button
                                onClick={handleRegister}
                                disabled={!formRequest.canSubmit}
                                className="btn w-100 btn-submit"
                            >
                                Submit
                            </button>
                        </div>
                    )}
                </div>
            );
        } else {
            return <></>;
        }
    };

    return (
        <div
            id="mainRegister"
            className="mx-auto max-w-9xl px-4 sm:px-6 lg:px-8"
        >
            <div className="app-content content">
                <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl text-center text-white">
                    Volunteer Registration
                </h2>
                {isLoading && isLoadingItems && <div>Please wait...</div>}
                {!isLoading && !isLoadingItems && !isError && renderForm()}
            </div>
        </div>
    );
}

export default VolunteerPage;
