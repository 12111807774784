import axios from "axios";
import { useQuery } from "react-query";
import { getApiInstance, isUserAuthenticated } from "./helper";
import { Feedback } from "../interfaces/interfaces";

export default function useFeedback(enable: boolean = true) {
    const query = useQuery<Feedback>(
        "feedback",
        async () => {
            const instance = getApiInstance();
            return instance.get("/me/feedback").then((result) => {
                return result.data.result;
            });
        },
        { staleTime: Infinity, enabled: enable && isUserAuthenticated() }
    );
    return { query };
}
