import { useEffect, useState } from "react";
import { AdminItemSummary } from "../interfaces/interfaces";
import { getAdminApiInstance } from "./helper";
import * as Sentry from "@sentry/react";

export default function useAdminSummary() {
    const [items, setItems] = useState<AdminItemSummary[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const getData = async (signal: AbortSignal) => {
            const instance = getAdminApiInstance();
            try {
                const rawResponse = await instance.get(
                    "/admin/registration/by-ticket",
                    {
                        signal: signal,
                    }
                );
                setItems(rawResponse.data.result);
            } catch (error: any) {
                Sentry.captureException(error);
                setIsError(true);
            } finally {
                setIsLoading(false);
            }
        };
        /**
         * Abort signal agar request api sebelumnya di cancel bila memerlukan reload dan request belum selesai
         */
        const controller = new AbortController();
        getData(controller.signal);
        return () => {
            controller.abort();
        };
    }, []);

    return { items, isLoading, isError };
}
