import axios from "axios";
import { MinistryDivision } from "../interfaces/interfaces";
import { useQuery } from "react-query";

export default function useMinistries() {
    const query = useQuery<MinistryDivision[]>(
        "ministries",
        async () => {
            return axios.get("/data/ministries-rev3.json").then((result) => {
                return result.data.ministries;
            });
        },
        { staleTime: Infinity }
    );
    return { query };
}
