import { useEffect, useState } from "react";
import { PickupLocation } from "../interfaces/interfaces";
import { getApiInstance } from "./helper";

export default function usePickupLocations(churchId: string) {
    const [locations, setLocations] = useState<PickupLocation[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (churchId) {
            const instance = getApiInstance();
            instance
                .get("/me/pickup-location", {
                    params: {
                        church_id: churchId,
                    },
                })
                .then((res) => {
                    setLocations(res.data.result);
                })
                .catch((exc) => {
                    setIsError(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setLocations([]);
            setIsLoading(false);
        }
    }, [churchId]);

    return { locations, isLoading, isError };
}
