import React, { useRef, useState } from "react";
import Header from "./partials/Header";
import { Volunteer } from "../interfaces/interfaces";
import DataTable from "react-data-table-component";
import { BaseFormGroup } from "./widgets/Form";
import { classNames, getAdminApiInstance } from "../utils/helper";
import useVolunteers from "../utils/useVolunteers";
import { useQueryClient } from "react-query";
import { FaRegTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import moment from "moment";

type VolunteerTableProps = {
    isLoading: boolean;
    volunteers?: Volunteer[];
};

function VolunteerTable(props: VolunteerTableProps) {
    const queryClient = useQueryClient();
    const handleRemove = async (id: string, name: string) => {
        const instance = getAdminApiInstance();
        Swal.fire({
            title: `Delete ${name} data?`,
            icon: "error",
            showCancelButton: true,
            cancelButtonText: "No",
            preConfirm: () => {
                return instance.delete(`/admin/volunteers/${id}`);
            },
            showLoaderOnConfirm: true,
        })
            .then((value) => {
                if (value.isConfirmed) {
                    queryClient.invalidateQueries({
                        queryKey: queryCacheKey,
                    });
                    Swal.close();
                }
            })
            .catch((exc) => {
                console.error(exc);
                Swal.fire({
                    title: "Error",
                    text: "Failed deleting data, please try again later.",
                    icon: "error",
                });
            });
    };
    const columns = [
        {
            name: "Name",
            selector: (row: Volunteer) => row.name,
            sortable: true,
        },
        {
            name: "Ministry",
            selector: (row: Volunteer) => row.ministry,
            wrap: true,
        },
        {
            name: "Bill No.",
            selector: (row: Volunteer) => row.bill_no,
            wrap: true,
        },
        {
            name: "Status",
            selector: (row: Volunteer) => row.payment_status,
            wrap: true,
        },
        {
            name: "Registered At",
            selector: (row: Volunteer) => row.bill_at,
            format: (row: Volunteer) =>
                moment(row.bill_at).format("D MMM YYYY HH:mm"),
            wrap: true,
        },
        {
            name: "",
            selector: (row: Volunteer) => row.person_id,
            format: (row: Volunteer) => {
                if (
                    row.bill_no &&
                    ["PAID", "WAITING"].includes(row.payment_status)
                ) {
                    return <></>;
                } else {
                    return (
                        <button
                            className="btn btn-error btn-outline btn-sm"
                            onClick={() =>
                                handleRemove(row.person_id, row.name)
                            }
                        >
                            <FaRegTrashAlt />
                        </button>
                    );
                }
            },
        },
    ];
    return (
        <div className="card">
            <DataTable
                columns={columns}
                progressPending={props.isLoading}
                striped
                pagination
                responsive
                subHeader
                data={
                    !props.isLoading && props.volunteers ? props.volunteers : []
                }
            />
        </div>
    );
}

type DialogAddVolunteerProps = {
    dialogRef?: (dialogRef: HTMLDialogElement) => void;
    queryCacheKey?: string;
};

function DialogAddVolunteer(props: DialogAddVolunteerProps) {
    const dialogRef = useRef<HTMLDialogElement | null>();
    const inputRef = useRef<HTMLInputElement | null>();
    const [isLoading, setIsLoading] = useState(false);
    const [inputError, setInputError] = useState("");
    const [currentStep, setCurrentStep] = useState(1);
    const [profile, setProfile] = useState<any>();
    const queryClient = useQueryClient();

    const handleAdd = async (e: any) => {
        e.preventDefault(); // preventing submit
        // cek NIJ
        const value = inputRef.current?.value;
        if (!value) {
            return;
        }
        const regex = /^\d+$/;
        if (!regex.test(value)) {
            setInputError("Invalid format");
            return;
        }
        setInputError("");
        // cek dari server
        setIsLoading(true);
        const instance = getAdminApiInstance();
        try {
            if (currentStep === 1) {
                const res = await instance.get(`/admin/volunteers/${value}`, {
                    timeout: 5000,
                });
                const data = res.data.result;
                setProfile({ ...data });
                setCurrentStep(2);
            } else {
                await instance.post(`/admin/volunteers/${profile.mygms_id}`);
                // refresh data
                queryClient.invalidateQueries({
                    queryKey: queryCacheKey,
                });
                // tutup dialog
                handleCancel();
            }
        } catch (exc) {
            console.error("Error at checking NIJ");
            console.error(exc);
            setInputError("Error at checking NIJ, please try again");
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setProfile(null);
        setCurrentStep(1);
        if (inputRef.current) {
            inputRef.current.value = "";
        }
        dialogRef.current?.close();
    };

    return (
        <dialog
            onClose={handleCancel}
            ref={(ref) => {
                if (ref != null) {
                    dialogRef.current = ref;
                    if (props.dialogRef) {
                        props.dialogRef(ref);
                    }
                }
            }}
            className="modal"
        >
            <div className="modal-box text-black">
                <div>
                    <form method="dialog">
                        {/* if there is a button in form, it will close the modal */}
                        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                            ✕
                        </button>
                    </form>
                    <form method="POST" onSubmit={handleAdd}>
                        <BaseFormGroup label="NIJ" textClass="text-black">
                            <input
                                ref={(ref) => (inputRef.current = ref)}
                                className={classNames(
                                    inputError ? "input-error" : "",
                                    "input input-bordered w-full"
                                )}
                                readOnly={isLoading}
                                disabled={currentStep === 2}
                                type="tel"
                                minLength={6}
                                maxLength={8}
                                placeholder="Fill NIJ here..."
                            />
                            {inputError && (
                                <div className="label">
                                    <span className="text-error">
                                        {inputError}
                                    </span>
                                </div>
                            )}
                        </BaseFormGroup>
                        {currentStep === 2 && (
                            <BaseFormGroup label="Name" textClass="text-black">
                                {profile.name}
                            </BaseFormGroup>
                        )}
                        <div className="mt-4 text-right">
                            <button
                                type="submit"
                                disabled={isLoading}
                                className="btn btn-success mr-4"
                            >
                                {isLoading && (
                                    <span className="loading loading-spinner loading-sm"></span>
                                )}
                                {currentStep === 1 && "Check Data"}
                                {currentStep === 2 && "Add"}
                            </button>
                            <button
                                type="button"
                                onClick={handleCancel}
                                className="btn btn-outline btn-error"
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </dialog>
    );
}

const queryCacheKey = "volunteers";
function AdminVolunteerPage() {
    const dialogRef = useRef<HTMLDialogElement | null>();
    const handleAddVolunteer = () => {
        if (dialogRef.current) dialogRef.current.showModal();
    };
    const { query } = useVolunteers(queryCacheKey);
    return (
        <div>
            <Header currentPage="admin/volunteers" />
            <div
                id="mainAdmin"
                className="mx-auto max-w-9xl px-4 sm:px-6 lg:px-8"
            >
                <div className="app-content content">
                    <div className="content-wrapper">
                        <div className="content-header row">
                            <div className="content-header-left">
                                <div className="breadcrumbs-top">
                                    <div className="col-12">
                                        <h2 className="content-header-title">
                                            Daftar Volunteer
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-body">
                            <section id="volunteers">
                                <div className="mb-2 mt-2">
                                    <button
                                        className="btn btn-primary"
                                        onClick={handleAddVolunteer}
                                    >
                                        Add Volunteer
                                    </button>
                                </div>
                                <DialogAddVolunteer
                                    dialogRef={(ref) => {
                                        dialogRef.current = ref;
                                    }}
                                    queryCacheKey={queryCacheKey}
                                />
                                <VolunteerTable
                                    isLoading={query.isLoading}
                                    volunteers={query.data}
                                />
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminVolunteerPage;
