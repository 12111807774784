import { VolunteerRegistrant } from "../interfaces/interfaces";
import { useEffect, useState } from "react";
import { getVolunteerApiInstance } from "./helper";

export default function useVolunteerRegistrant() {
    const [data, setData] = useState<VolunteerRegistrant>();
    const [isLoading, setIsloading] = useState(true);
    const [isError, setIsError] = useState(false);
    useEffect(() => {
        const token = sessionStorage.getItem("volunteer_token");
        if (!token) return;
        const instance = getVolunteerApiInstance();
        instance
            .get("/profile")
            .then((res) => {
                setData(res.data.result);
                setIsloading(false);
            })
            .catch((err) => {
                setIsError(true);
            });
    }, []);

    return {
        data,
        isLoading,
        isError,
    };
}
