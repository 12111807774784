import { useQuery } from "react-query";
import { getApiInstance } from "./helper";
import { Workshop } from "../interfaces/interfaces";

export default function useWorkshops(queryCacheKey: string, churchId: string) {
    const query = useQuery<Workshop[]>(
        `${queryCacheKey}-${churchId}`,
        async () => {
            if (!churchId) return [];
            const instance = getApiInstance();
            return instance
                .get("/me/workshops", {
                    params: {
                        church_id: churchId,
                    },
                })
                .then((result) => {
                    return result.data.result;
                });
        },
        { staleTime: Infinity }
    );
    return { query };
}
