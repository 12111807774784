import "./App.scss";
import React, { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import RegisterPage from "./pages/RegisterPage";
import {
    isAuthenticated,
    isOperatorAuthenticated,
    isVolunteerAuthenticated,
    login,
    logout,
    operatorLogin,
    volunteerLogin,
} from "./utils/helper";
import Swal from "sweetalert2";
import OperatorHistories from "./pages/OperatorHistories";
import AdminRegistrantPage from "./pages/AdminRegistrantPage";
import AdminRegistrantByTicketsPage from "./pages/AdminRegistrantByTicketsPage";
import VolunteerPage from "./pages/VolunteerPage";
import AdminVolunteerPage from "./pages/AdminVolunteerPage";
import { IntlProvider } from "react-intl";
// import { BrowserTracing } from "@sentry/tracing";
import messagesEn from "./intl/en.json";
import { jwtDecode } from "jwt-decode";
import LoadingScreenWidget from "./pages/widgets/LoadingScreenWidget";

const AdminPage = React.lazy(() => import("../src/pages/AdminPage"));
const FeedbackPage = React.lazy(() => import("../src/pages/FeedbackPage"));
const AdminQuotaPage = React.lazy(() => import("../src/pages/AdminQuotaPage"));
const OperatorPage = React.lazy(() => import("../src/pages/OperatorPage"));
const AdminDashboardPage = React.lazy(
    () => import("../src/pages/AdminDashboardPage")
);
const AdminWorkshopDashboardPage = React.lazy(
    () => import("../src/pages/AdminWorkshopDashboardPage")
);

function App() {
    const query = new URLSearchParams(useLocation().search);
    const authCode = query.get("auth_code") || query.get("code");
    const pathname = window.location.pathname;
    // khusus url yang perlu login
    if (pathname === "/admin/logout") {
        logout();
        return <div>Logging you out...</div>;
    } else if (pathname.startsWith("/admin")) {
        const code = query.get("code");
        // permintaan untuk login ulang
        if (!code) {
            // khusus bila belum pernah login, dan tidak ada parameter code, diabaikan saja
            if (!isAuthenticated()) {
                const redirectUrl = process.env.REACT_APP_BASE_URL + "/admin";
                const clientId = "61df7b4dedd41afd950e06851d3e6d9b";
                let url = `https://accounts.gms.church/oauth/authorize?scope=profile&state=state&client_id=${clientId}&redirect_uri=${redirectUrl}`;
                window.location.href = url;
                return <div></div>;
            }
        } else {
            // bila ada code, tukarkan ke token admin
            login(code)
                .then((success: boolean) => {
                    if (success) {
                        window.location.href = "/admin"; // arahkan ulang ke halaman admin
                    } else {
                        Swal.fire(
                            "Login Failed",
                            "Login gagal! Silahkan periksa apakah Anda login menggunakan user yang benar.",
                            "error"
                        );
                    }
                })
                .catch((exc) => {
                    console.error(exc);
                    Swal.fire("Error", "Something went wrong!", "error");
                });
            return <div></div>;
        }
    } else if (pathname.startsWith("/operator")) {
        if (!authCode) {
            // khusus bila belum pernah login, dan tidak ada parameter code, diabaikan saja
            if (!isOperatorAuthenticated()) {
                const redirectUrl =
                    process.env.REACT_APP_BASE_URL + "/operator";
                const clientId = "61df7b4dedd41afd950e06851d3e6d9b";
                let url = `https://accounts.gms.church/oauth/authorize?scope=profile&state=state&client_id=${clientId}&redirect_uri=${redirectUrl}`;
                window.location.href = url;
                return <div></div>;
            }
        } else {
            // bila ada code, tukarkan ke token admin
            operatorLogin(authCode)
                .then((success: boolean) => {
                    if (success) {
                        window.location.href = "/operator"; // arahkan ulang ke halaman admin
                    } else {
                        Swal.fire(
                            "Login Failed",
                            "Login gagal! Silahkan periksa apakah Anda login menggunakan user yang benar.",
                            "error"
                        );
                    }
                })
                .catch((exc) => {
                    console.error(exc);
                    Swal.fire("Error", "Something went wrong!", "error");
                });
            return <div></div>;
        }
    } else if (pathname.startsWith("/volunteer")) {
        if (!authCode) {
            // khusus bila belum pernah login, dan tidak ada parameter code, diabaikan saja
            if (!isVolunteerAuthenticated()) {
                const redirectUrl =
                    process.env.REACT_APP_BASE_URL + "/volunteer";
                const clientId = "61df7b4dedd41afd950e06851d3e6d9b";
                let url = `https://accounts.gms.church/oauth/authorize?scope=profile&state=state&client_id=${clientId}&redirect_uri=${redirectUrl}`;
                window.location.href = url;
                return <div></div>;
            }
        } else {
            // bila ada code, tukarkan ke token volunteer
            volunteerLogin(authCode)
                .then((success: boolean) => {
                    if (success) {
                        window.location.href = "/volunteer"; // arahkan ulang ke halaman volunteer
                    } else {
                        Swal.fire(
                            "Login Failed",
                            "Login gagal! Silahkan periksa apakah Anda login menggunakan user yang benar.",
                            "error"
                        );
                    }
                })
                .catch((exc) => {
                    console.error(exc);
                    Swal.fire("Error", "Something went wrong!", "error");
                });
            return <div></div>;
        }
    }
    const allowedLocales = ["id", "en"];
    let locale: string = sessionStorage.getItem("locale") ?? "id";
    if (authCode) {
        // simpan code untuk login
        sessionStorage.setItem("auth_code", authCode);
        // set locale sesuai token
        const decoded: any = jwtDecode(authCode);
        if (decoded.locale && decoded.locale !== locale) {
            locale = decoded.locale;
            sessionStorage.setItem("locale", locale);
        }
    }
    if (!allowedLocales.includes(locale)) {
        locale = "id"; // jika tidak sesuai dengan ketentuan, pilih default indonesia
        sessionStorage.setItem("locale", locale);
    }

    return (
        <Suspense fallback={<LoadingScreenWidget />}>
            <IntlProvider
                locale={locale}
                defaultLocale="id"
                messages={locale === "en" ? messagesEn : undefined}
            >
                <Routes>
                    <Route path="/" element={<RegisterPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    {/* <Route path="/feedback" element={<FeedbackPage />} /> */}
                    <Route path="/admin" element={<AdminPage />} />
                    <Route
                        path="/admin/registrants"
                        element={<AdminRegistrantPage />}
                    />
                    <Route
                        path="/admin/registrants/by-tickets"
                        element={<AdminRegistrantByTicketsPage />}
                    />
                    <Route path="/admin/quota" element={<AdminQuotaPage />} />
                    <Route
                        path="/admin/dashboard"
                        element={<AdminDashboardPage />}
                    />
                    <Route
                        path="/admin/dashboard/workshop"
                        element={<AdminWorkshopDashboardPage />}
                    />
                    <Route
                        path="/admin/volunteers"
                        element={<AdminVolunteerPage />}
                    />
                    <Route path="/operator" element={<OperatorPage />} />
                    <Route
                        path="/operator/histories"
                        element={<OperatorHistories />}
                    />
                    <Route path="/volunteer" element={<VolunteerPage />} />
                </Routes>
            </IntlProvider>
        </Suspense>
    );
}

export default App;
