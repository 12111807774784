import "./admin.scss";
import React from "react";
import Header from "./partials/Header";
import DataTable, { Alignment } from "react-data-table-component";
import useAdminSummary from "../utils/useAdminSummary";
import moment from "moment";
import { utils, writeFileXLSX } from "xlsx";

function AdminRegistrantByTicketsPage() {
    const { items, isLoading } = useAdminSummary();

    const handleExportData = async () => {
        if (!items) {
            return;
        }
        // generate excel
        const worksheet = utils.json_to_sheet(
            items.map((o) => {
                return {
                    name: o.name,
                    status: o.status,
                    payment_channel: o.payment_channel ?? "-",
                    total: o.total,
                };
            })
        );
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Sheet1");
        // create file download
        const filename =
            "export summary - " +
            moment().utc().add(7, "hours").format("YYYYMMDD HHmmss") +
            ".xlsx";
        worksheet["!cols"] = [{ wch: 12 }, { wch: 20 }];
        writeFileXLSX(workbook, filename);
    };

    // schema column
    const columns = [
        {
            name: "Name",
            selector: (row: any) => row.name,
            sortable: true,
            wrap: true,
        },
        {
            name: "Status",
            selector: (row: any) => row.status,
            format: (row: any) => {
                if (row.status === "PAID") {
                    return (
                        <span className="badge badge-success">
                            {row.status}
                        </span>
                    );
                } else {
                    return (
                        <span className="badge badge-secondary">
                            {row.status}
                        </span>
                    );
                }
            },
            wrap: true,
            sortable: true,
        },
        {
            name: "Payment Channel",
            selector: (row: any) => row.payment_channel,
            sortable: true,
        },
        {
            name: "Total",
            selector: (row: any) => row.total,
            sortable: true,
        },
    ];

    return (
        <div>
            <Header currentPage={"admin/registrants/by-tickets"} />
            <div
                id="mainAdmin"
                className="mx-auto max-w-9xl px-4 sm:px-6 lg:px-8"
            >
                {/* content */}
                <div className="app-content content">
                    <div className="content-wrapper">
                        <div className="content-header row">
                            <div className="content-header-left">
                                <div className="breadcrumbs-top">
                                    <div className="col-12">
                                        <h2 className="content-header-title">
                                            Summary
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-body">
                            {isLoading && <div>Loading...</div>}
                            {!isLoading && (
                                <>
                                    <div className="mb-2 mt-2">
                                        <button
                                            className="btn btn-primary"
                                            onClick={handleExportData}
                                        >
                                            Export Excel
                                        </button>
                                    </div>
                                    <div className="card">
                                        {items && (
                                            <DataTable
                                                columns={columns}
                                                data={items}
                                                striped
                                                pagination={false}
                                                responsive
                                                subHeader
                                                subHeaderAlign={Alignment.RIGHT}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminRegistrantByTicketsPage;
