import { useQuery } from "react-query";
import { Volunteer } from "../interfaces/interfaces";
import { getAdminApiInstance } from "./helper";

export default function useVolunteers(queryCacheKey: string) {
    const query = useQuery<Volunteer[]>(
        queryCacheKey,
        async () => {
            const instance = getAdminApiInstance();
            return instance.get("admin/volunteers").then((result) => {
                return result.data.result;
            });
        },
        { staleTime: Infinity }
    );
    return { query };
}
